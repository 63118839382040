import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const Plans = () => {
  return (
    <div className="relative flex flex-col w-full min-h-screen  bg-red-1 px-2 py-16 md:py-24 md:px-20">
      <div className="flex justify-center items-center w-full mb-12 md:mb-20">
        <div className="flex flex-col text-center">
          <div className="flex items-center justify-center">
            <img
              className="w-16 h-16 md:w-24 md:h-24 mr-3 slide-in-left"
              src="./logo.png"
              alt="Logo"
            />
            <p className="text-white text-4xl md:text-6xl font-bold">Pricing & Plans</p>
          </div>
          <p className="text-white text-sm md:text-xl -mt-1 sm:px-0 px-5 sm:-mt-2">
            Everyone has different needs, so we offer 3 plans to choose from!
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 px-4 md:px-6">
        {[
          {
            title: "Pay-Per-Package",
            description: [
              "One-time token purchase.",
              "Each token is valid for one package.",
              "Great if you order occasionally.",
            ],
          },
          {
            title: "1-Month Plan",
            description: [
              "Valid for one month (non-renewing).",
              "Unlimited package pickups.",
              "Great if you order frequently.",
            ],
          },
          {
            title: "3-Month Plan",
            description: [
              "Valid for three months (non-renewing).",
              "Unlimited package pickups.",
              "Great if you order very frequently.",
            ],
          },
        ].map((plan, index) => (
          <div
            key={index}
            className="bg-white rounded-lg p-6 md:p-8 py-10 md:py-12 text-center shadow-lg transform transition duration-300 hover:scale-105"
          >
            <p className="text-lg md:text-xl font-semibold text-gray-700 mb-4 md:mb-5">{plan.title}</p>
            <div className="h-[2px] w-10 md:w-12 bg-red-500 mx-auto mb-5 md:mb-7"></div>
            <div className="flex flex-col space-y-2 md:space-y-3 text-gray-600 text-sm">
              {plan.description.map((item, idx) => (
                <p key={idx}>{item}</p>
              ))}
            </div>
            <button className="mt-6 md:mt-8 bg-gray-100 text-gray-500 text-sm py-2 px-4 md:px-6 rounded-lg transition-all duration-300">
              View price on the app.
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};


import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export const Home = () => {

    const navigate = useNavigate();
    
    return(
        <div className="flex flex-col justify-center">
            

            {/* Hero Image */}
            <div className="relative flex flex-row w-full h-screen -mt-16 bg-white bg-opacity-20">

                {/* Map */}
                <img className="absolute w-full h-full opacity-60 mix-blend-multiply brightness-[.7] saturate-[.07] contrast-125 blur-[0px]" 
                    style={{
                    backgroundImage: `url('./new-haven-streetview.jpg')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%',
                    backgroundAttachment: 'fixed',
                }}/>

                <div className="relative flex flex-col w-full h-full self-center items-center justify-center">
                        <div className="px-7 sm:mt-0 mt-16">
                            <div className="flex sm:flex-row flex-col justify-center mr-0 sm:mr-5 items-center sm:items-start">
                                <motion.img 
                                    className="sm:w-32 sm:h-32 sm:mb-0 -mb-9 w-36 h-36 sm:mr-3 mr-0"
                                    src="./logo.png"
                                    initial={{ x: '-100vw', }}
                                    animate={{ x: 0, }}
                                    transition={{ type: "spring", stiffness: 50, damping: 11 }}
                                />
                                <p className=" md:text-8xl sm:text-7xl text-6xl text-center font-extrabold text-red-1 mt-7 md:mt-4 drop-shadow-lg mb-5 md:mb-5">PickPackGo</p>
                            </div>
                            <p className="text-gray-800 font-medium md:font-semibold md:text-2xl text-2xl text-center drop-shadow-sm md:mb-1">An <a className="bg-red-1 text-white px-2 md:mx-1 rounded-md drop-shadow-lg  font-semibold">affordable</a> and <a className="bg-red-1 text-white px-2 md:mx-1 rounded-md drop-shadow-lg font-semibold">secure</a> solution for package pickups.</p>
                            <p className="text-gray-800 font-medium md:font-semibold md:text-2xl text-2xl text-center drop-shadow-sm invisible sm:visible">Powered by local businesses.</p>
                        </div>
                    <div className="absolute flex top-20 md:right-20 flex-row justify-center space-x-1">
                        <a href="https://apps.apple.com/us/app/pickpackgo/id6738035697"><img className="md:w-36 md:mr-2 w-40 shadow-lg hover:scale-105 border border-white rounded-md transition-all duration-150 hover:contrast-75" src="./Download_on_the_App_Store_Badge.svg"></img></a>
                        <a href="/androidDownload"><img className="md:w-36 w-40 shadow-lg hover:scale-105 border border-white rounded-md transition-all duration-150 hover:contrast-75" src="./Google_Play_Store_badge_EN.svg"></img></a>
                    </div>
                </div>

            </div>


            {/* Arrow */}
            <div className="relative flex">
                <div className="absolute flex justify-center items-center bg-red-1 rounded-full h-14 w-14 -bottom-7 left-1/2 transform -translate-x-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="animate-bounce h-6 w-6 fill-white">
                        <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" />
                    </svg>
                </div>
            </div>

            {/* "About" Section */}
            <div className="grid grid-cols-1 grid-rows-6 sm:grid-rows-7 md:grid-cols-7 md:grid-rows-1 py-14 lg:py-28 bg-red-1 px-5 sm:px-16 md:px-20 min-h-fit h-screen gap-y-7 md:gap-y-0 md:gap-x-7">

                <div className=" relative row-span-2 sm:row-span-3 md:col-span-3 rounded-lg" style={{ backgroundImage: `url('./package-stolen-photo.png')`, backgroundSize: 'cover' }}>
                </div>

                <div className="flex row-span-4 md:col-span-4 flex-col h-full">

                    <div className="flex flex-col h-2/3 justify-center bg-gray-100 md:px-12 px-9 py-10 md:py-10 lg:py-16 rounded-lg shadow-md text-black text-sm md:text-base">
                        <p className="text-3xl mb-4 font-bold text-red-1">Hate missing your packages?</p>

                        <p className="text-lg">
                            PickPackGo collaborates with local businesses that have agreed to receieve and 
                            hold packages for you. Say goodbye to missing packages!
                        </p>
                    </div>

                    <div className="grid grid-cols-2 min-h-fit h-1/3 items-end grid-rows-1 gap-x-4 flex-col justify-end rounded-lg md:text-base mt-7">
                        <div className="h-full border-r px-2 py-8 md:py-8 bg-gray-100 rounded-lg">
                            <div className="flex justify-center items-center w-full h-full flex-col-reverse">
                                <p className="text-lg w-full rounded-lg text-center sm:px-8">
                                    Our prices are <a className="font-semibold bg-red-600 px-2 py-[2px] rounded-md text-white whitespace-nowrap">48% cheaper</a> than USPS lockers.
                                </p>
                            </div>
                        </div>
                        <div className="h-full px-2 py-8 md:py-8 bg-gray-100 rounded-lg">
                            <div className="flex flex-col-reverse justify-center items-center w-full h-full">
                                <p className="text-lg w-full rounded-lg text-center sm:px-8">
                                    Most of our partner have pickup hours <a className="font-semibold whitespace-nowrap bg-red-600 px-2 py-[2px] rounded-md text-white">past 5PM</a>.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className="flex flex-col text-base items-center justify-center my-auto ml-5 text-center py-14 min-h-fit h-screen">

                <div className="flex flex-col w-11/12 px-5 sm:px-10 mt-10 pb-10">
                        
                    <p className="mb-16 sm:mb-20 text-3xl sm:text-4xl font-bold text-red-1 ">As easy as pick, pack, go!</p>

                    <div className="grid md:grid-cols-3 md:grid-rows-none grid-rows-3 gap-12 sm:gap-16 place-items-center">
                        <div className="flex flex-col items-center max-w-[400px]">
                            <img className="w-[70px] h-[70px] mb-5 sm:mb-10" src="./step1.png"></img>
                            <p className=" text-xl sm:text-2xl font-bold mb-1 text-red-2">1. Purchase a token or plan</p>
                            <p className="text-base">
                                Purchase a one-time pickup token or a subscription plan from one of our local business 
                                partners via our app.
                            </p>
                        </div>
                        <div className="flex flex-col items-center max-w-[400px]">
                            <img className="w-[70px] h-[70px] mb-5 sm:mb-10" src="./step2.png"></img>
                            <p className="text-xl sm:text-2xl mb-1 font-bold text-red-2">2. Set your delivery address</p>
                            <p className="text-base">
                                Set your package's delivery address to the location of the businesses from which you
                                purchased that token or plan.
                            </p>
                        </div>
                        <div className="flex flex-col items-center max-w-[400px]">
                            <img className="w-[70px] h-[70px] mb-5 sm:mb-10" src="./step3.png"></img>
                            <p className="text-xl sm:text-2xl mb-1 font-bold text-red-2">3. Pick up your package</p>
                            <p className="text-base">
                                To pick up your packge once it arrives, go to the local business and show your purchase 
                                confirmation along with your ID.
                            </p>
                        </div>
                    </div>
                </div>

            </div> 




        </div>
    )
}
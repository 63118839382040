import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export const Header = ({ currentPage }) => {
    const [popupActive, setPopupActive] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            {/* Header Container */}
            <div className="z-10 sticky flex top-0 w-full h-[45px] items-center bg-gray-100 px-5 sm:px-20 justify-between sm:justify-start text-sm z-30">


                {/* Logo or Brand */}
                <button onClick={() => navigate("/")} className="font-bold text-lg text-red-2">PickPackGo</button>

                {/* Desktop Navigation */}
                <div className="hidden sm:flex font-semibold">
                    <button onClick={() => navigate("/plans")} className="hover:text-red-3 transition duration-200 pl-8">Pricing & Plans</button>
                    <button onClick={() => navigate("/customerService")} className="hover:text-red-3 transition duration-200 pl-8">Customer Service</button>
                    <button onClick={() => navigate("/FAQ")} className="hover:text-red-3 transition duration-200 pl-8">FAQ</button>
                </div>

                {/* Mobile Hamburger Icon */}
                <button 
                    className="sm:hidden flex items-center" 
                    onClick={() => setPopupActive(!popupActive)}
                >
                    <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
            
            <AnimatePresence>
            {popupActive && (
                <motion.div
                className="sm:hidden fixed top-[45px] left-0 w-full bg-neutral-100 shadow-lg z-10"
                initial={{ y: "-100%", opacity: 0 }} // Start off-screen (right side)
                animate={{ y: 0, opacity: 1 }} // Move to the center
                exit={{ y: "-100%", opacity: 0 }} // Move back off-screen when exiting
                transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                }}
                >
                    <button
                        onClick={() => {
                        navigate("/");
                        setPopupActive(false);
                        }}
                        className="block w-full text-center px-4 py-2 hover:bg-red-200"
                    >
                        Home
                    </button>
                    <button
                        onClick={() => {
                        navigate("/plans");
                        setPopupActive(false);
                        }}
                        className="block w-full text-center px-4 py-2 hover:bg-red-200"
                    >
                        Pricing & Plans
                    </button>
                    <button
                        onClick={() => {
                        navigate("/customerService");
                        setPopupActive(false);
                        }}
                        className="block w-full text-center px-4 py-2 hover:bg-red-200"
                    >
                        Customer Service
                    </button>
                    <button
                        onClick={() => {
                        navigate("/FAQ");
                        setPopupActive(false);
                        }}
                        className="block w-full text-center px-4 py-2 hover:bg-red-200"
                    >
                        FAQ
                    </button>
                </motion.div>
            )}
            </AnimatePresence>

            {/* Content Rendered by Routes */}
            <Outlet />
        </>
    );
};

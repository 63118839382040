import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const Footer = ({ currentPage }) => {
    const [popupActive, setPopupActive] = useState(false);
    const navigate = useNavigate();

    return (
        <>
        
        <Outlet />

           {/* Footer */}
            <footer className="flex md:flex-row flex-col items-center w-full py-5 bg-red-1 border-t border-red-300 text-red-300 relative px-5 sm:px-20 text-sm justify-between">

            <p>&copy; {new Date().getFullYear()} PickPackGo. All rights reserved.</p>

            {/* Center Section */}
            <div className="flex md:mt-0 mt-1">
                <div className="flex space-x-4">
                <button
                    className="hover:underline"
                    onClick={() => navigate("/privacyPolicy")}
                >
                    Privacy Policy
                </button>
                <span>|</span>
                <button className="hover:underline" onClick={() => navigate("/terms")}>
                    Terms
                </button>
                <span>|</span>
                <button
                    className="hover:underline"
                    onClick={() => navigate("/customerService")}
                >
                    Customer Service
                </button>
                </div>
            </div>
            </footer>


        </>
    );
};
        
        
     
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Home } from './pages/home';
import { EmailVerified } from './pages/emailVerified';
import { PrivacyPolicy } from './pages/privacyPolicy';
import { Terms } from './pages/terms';
import { Header } from './components/header';
import { Plans } from './pages/plans';
import { AndroidTesting } from './pages/androidTesting';
import { CustomerService } from './pages/customerService';
import { Footer } from './components/footer';
import { Faq } from './pages/faq';

function App() {
  return (
    <Router>
      <div className="relative">
        <Routes>

          <Route path="/" element={<Header />}>
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/customerService" element={<CustomerService />} />
            <Route path="/emailVerified" element={<EmailVerified />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/androidDownload" element={<AndroidTesting />} />
            <Route path="/FAQ" element={<Faq/>} />
          </Route>
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import { auth } from "../firebase/config"; // Ensure correct path to firebaseConfig.js

export const EmailVerified = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("Verifying your email...");

    useEffect(() => {
        const code = searchParams.get("oobCode");
        console.log("Verification code:", code);

        if (code) {
            applyActionCode(auth, code)
                .then(() => {
                    setMessage("Your email has been successfully verified. Now you can log in.");
                })
                .catch((error) => {
                    console.error("Error verifying email:", error);
                    setMessage("Your email has been successfully verified. Now you can log in.");
                    // setMessage("Invalid or expired verification link. Please request a new verification email.");
                });
        } else {
            setMessage("Verification code not found.");
        }
    }, [searchParams]);

    const handleHomeClick = () => {
        navigate("/"); // Redirect to home or login page
    };

    return (
        <div className="flex flex-col justify-center items-center bg-red-2 text-white min-h-[calc(100vh_-_45px)]">
            <div className="flex flex-col items-center mb-8">
                <img src="./logo.png" alt="PickPackGo Logo" className="w-28 mb-4" />
                <h1 className="text-3xl font-semibold">
                    {message === "Verifying your email..."
                        ? "Verifying..."
                        : message === "Your email has been successfully verified. Now you can log in."
                        ? "Email Verified!"
                        : "Hm.. Something went wrong."}
                </h1>
                <p className="text-lg italic font-light mt-2">{message}</p>
            </div>

            {message === "Your email has been successfully verified. Now you can log in." && (
                <div className="flex justify-center mb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="w-20 h-20 fill-current text-green-500">
                        <path d="M45 0C20.15 0 0 20.15 0 45s20.15 45 45 45 45-20.15 45-45S69.85 0 45 0zm0 85C23.215 85 5 66.785 5 45S23.215 5 45 5s40 18.215 40 40-18.215 40-40 40zm-4.833-22.667l-15-15c-1.536-1.536-1.536-4.025 0-5.561 1.536-1.536 4.025-1.536 5.561 0L41.25 52.806 64.273 29.783c1.536-1.536 4.025-1.536 5.561 0 1.536 1.536 1.536 4.025 0 5.561l-26.667 26.667c-1.536 1.536-4.025 1.536-5.561 0z" />
                    </svg>
                </div>
            )}

            {/* <button onClick={handleHomeClick} className="text-xl bg-white hover:bg-red-200 text-red-2 px-6 py-2 rounded-full">
                Go to Homepage
            </button> */}
        </div>
    );
};

import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { motion } from "framer-motion";

export const CustomerService = () => {
  const formRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_74yvs5o",
        "template_j608yuw",
        formRef.current,
        "20PJsPnKHb8kdodMU"
      );
      setIsSubmitted(true);
    } catch (error) {
      console.error("Email failed to send:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full bg-red-1 py-16 md:py-32 px-5 min-h-screen">
      <h1 className="text-4xl sm:text-6xl font-bold text-white text-center">Customer Service</h1>
      <p className="text-white mt-2 text-sm sm:text-xl mb-16 text-center max-w-2xl">
        Need assistance? Fill out this form, and we'll respond in less than 24 hours.
      </p>
      {isSubmitted ? (
        <motion.div
          className="flex flex-col items-center bg-white text-gray-700 shadow-lg rounded-lg py-10 px-8 max-w-md w-full"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.5,
            scale: { type: "spring", stiffness: 300, damping: 20 },
          }}
        >
          <motion.div
            className="text-red-600 rounded-full mb-4"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              damping: 15,
            }}
          >
            <motion.svg
              className="w-20 h-20"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1 }}
            >
              <circle cx="12" cy="12" r="10" strokeWidth="2" />
              <motion.path
                d="M9 12l2 2l4-4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
          </motion.div>
          <p className="text-xl font-semibold">Request Sent!</p>
          <p className="mt-2 text-center text-gray-600">
            Thank you for reaching out. Our team will respond as soon as possible.
          </p>
        </motion.div>
      ) : (
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full"
        >
          <div className="mb-4">
            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstname"
              id="firstname"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastname"
              id="lastname"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500 "
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Account Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              pattern="^\+?\d{0,13}"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500"
              placeholder="e.g., +1234567890"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="topic" className="block text-sm font-medium text-gray-700">
              Topic
            </label>
            <select
              name="topic"
              id="topic"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500"
              required
            >
              <option value="" disabled selected>
                Select a topic
              </option>
              <option value="Billing">Billing</option>
              <option value="Package Concern">Package Concern</option>
              <option value="Technical Support">Technical Support</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              rows="4"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-red-500 focus:border-red-500"
              required
              placeholder="Please describe your situation in detail here!"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-red-600 text-white font-medium mt-4 py-3 px-4 rounded-md shadow hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 transition-all duration-300"
          >
            Send Request
          </button>
        </form>
      )}
    </div>
  );
};

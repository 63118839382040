import { useState } from "react";

export const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How soon after a package is delivered can I pick it up?",
      answer:
        "Packages can be picked up immediately after you receive a notification from your carrier. All you need to do is show an ID (school ID is acceptable) and your confirmation pick-up receipt to an employee. Your confirmation receipt is accessible via the “Active Pickups” section in your settings.",
    },
    {
      question: "Why do I need to present an ID when picking up a package?",
      answer:
        "We do this to ensure that all packages are picked up by the correct person. Please ensure the receipt name of your package matches the name shown on your confirmation receipt.",
    },
    {
      question: "If my package is lost or damaged, does PickPackGo cover this?",
      answer:
        "The contract for delivery exists between you and your chosen delivery carrier. We are not liable for packages lost, damaged, or stolen during transit. By utilizing our service, you adhere to these terms. However, if proof is provided that a business is responsible for your lost or damaged package, we will issue you a refund of up to $25 of your package’s value and a full refund for your token or subscription plan. Please direct any concerns or issues to our email: contact@pickpackgo.app.",
    },
  ];

  return (
    <div className="flex flex-col  bg-red-1 py-16 px-6 md:py-32 md:px-20 min-h-screen items-center">
      {/* FAQ Section */}
      <div className="flex flex-col w-full md:w-[700px]">
        <div className="text-center mb-12 md:mb-16">
          <p className="text-4xl md:text-6xl font-bold text-white">FAQ</p>
        </div>

        <div className="space-y-7 w-full">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden transition-transform w-full"
            >
              <div className="sm:hidden">
                <button
                  className="w-full text-left px-4 md:px-6 py-4 font-semibold text-gray-700"
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                </button>
              </div>
              <div className="hidden sm:block">
                <div className="w-full text-left px-4 md:px-6 pt-4 font-semibold text-gray-700">
                  {faq.question}
                </div>
              </div>
              <div className={`px-4 md:px-6 sm:pt-3 pt-0 pb-3 text-gray-600 text-sm sm:block ${openIndex === index ? 'block' : 'hidden'}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

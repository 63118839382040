import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const Terms = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100 justify-center items-center py-16 px-5 text-sm">
      <div className="flex flex-col bg-white shadow-lg rounded-lg ">
        <div className="bg-white shadow-lg rounded-lg max-w-4xl w-full py-9 sm:py-16 px-7 sm:px-12">
          <h1 className="text-2xl font-bold text-red-2 mb-8 text-center">
            Terms of Service
          </h1>
          <div className="text-gray-700 space-y-6">
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                Acceptance of Terms
              </h2>
              <p>
                By accessing and using our application (“App”), you agree to be
                bound by these Terms of Service (“Terms”). These Terms govern
                your use of our package receiving service that connects users
                with local businesses for package delivery purposes.
              </p>
            </section>
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                Service Description
              </h2>
              <p>
                Our App provides a platform that enables users to:
              </p>
              <ul className="list-disc ml-6 space-y-2 mt-2 mb-2">
                <li>
                  Designate local participating businesses as delivery points for
                  packages.
                </li>
                <li>
                  Purchase “tokens” or a subscription plan to utilize our
                  service.
                </li>
              </ul>
              <p>
                We act solely as an intermediary platform connecting users with
                participating businesses and do not provide delivery services
                directly.
              </p>
            </section>
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                Liability and Responsibilities
              </h2>
              <p>
                We are not a courier service and do not physically handle or
                transport packages. Claims for lost or damaged packages must be
                filed directly with the delivery carrier. Service limitations
                may apply based on the availability of participating businesses.
              </p>
            </section>
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                User Obligations
              </h2>
              <ul className="list-disc ml-6 space-y-2">
                <li>Provide accurate delivery information.</li>
                <li>Not send prohibited or illegal items.</li>
                <li>Retrieve packages within the designated timeframe.</li>
                <li>Present valid identification when collecting packages.</li>
                <li>Pay any applicable service fees.</li>
              </ul>
            </section>
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                Additional Terms
              </h2>
              <p>
                We reserve the right to modify these Terms at any time. Continued
                use of the App after changes constitutes acceptance of modified
                Terms. Additionally, we may suspend or terminate your access for
                violations of these Terms.
              </p>
            </section>
            <section>
              <h2 className="text-base font-bold text-gray-800 mb-2">
                Contact Information
              </h2>
              <p>
                For questions regarding these Terms, please contact us at{" "}
                <a
                  href="mailto:contact@pickpackgo.app"
                  className="text-red-600 underline"
                >
                  contact@pickpackgo.app
                </a>
                .
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const AndroidTesting = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gradient-to-b from-red-500 to-red-700 text-white min-h-screen px-5">
      <img
        src="./logo.png"
        alt="PickPackGo Logo"
        className="w-32 mb-6 drop-shadow-md"
      />

      <div className="flex flex-col items-center bg-white text-gray-800 rounded-xl shadow-lg p-10 max-w-[600px]">
        <h1 className="text-4xl md:text-4xl font-semibold text-center mb-7 sm:mb-4">
          Almost there!
        </h1>
        <p className="text-center text-base md:text-lg font-light mb-2">
          The Google Play Store requires new apps to specify who can download it.
        </p>

        <p className="text-center text-base md:text-lg font-light">
          For now, please join our temporary Google Group <a className="text-red-600 underline font-medium hover:text-red-700 transition" href="https://groups.google.com/u/1/g/pickpackgo-android-testing">here</a>
          , which will give you permission to download PickPackGo on the Play Store. Then, install the 
          app <a className="text-red-600 underline font-medium hover:text-red-700 transition" href="https://play.google.com/store/apps/details?id=com.jgarcia084.pickpackgo">here</a>
          !
        </p>
      </div>
    </div>
  );
};

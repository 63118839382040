import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100 justify-center items-center py-16 px-5 text-sm">
      <div className="flex flex-col bg-white shadow-lg rounded-lg ">
        <div className="bg-white shadow-lg rounded-lg max-w-4xl w-full py-9 sm:py-16 px-7 sm:px-12">
          <h1 className="text-2xl font-bold text-red-2 mb-8 text-center">
          Privacy Policy
        </h1>
        <div className="text-gray-700 space-y-6">
          <p>
            Aranza Rodriguez and Juliette Garcia built the PickPackGo app to provide an affordable and convenient solution to package pickup. This SERVICE is provided by Aranza Rodriguez and Juliette Garcia and includes various purchasing plans for those seeking a safe alternative for package delivery.
          </p>
          <p>
            This page informs visitors about our policies regarding the collection, use, and disclosure of personal information for anyone using our Service. By using our Service, you agree to the collection and use of information as outlined in this policy. We collect personal information to provide and improve the Service. We will not use or share your information except as described in this Privacy Policy.
          </p>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Information Collection and Use
            </h2>
            <p className="mb-2"> 
              We may collect information about you in various ways. This includes data collected via the app depending on the content and materials you use. Key examples include:
            </p>
            <ul className="list-disc ml-6 space-y-2">
              <li>
                Third-Party Data: We may collect data from accounts linked to third-party services (e.g., Google) to facilitate account creation and logon.
              </li>
              <li>
                Bank and Credit Card Data: Payments are processed through Stripe, which collects information such as your name, purchase date, amount, and payment method.
              </li>
              <li>
                Cookies: While the Service does not explicitly use cookies, third-party services integrated into the app may use them. You can choose to accept or refuse cookies, but some app functionalities may be limited if cookies are declined.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Service Providers
            </h2>
            <p className="mb-2"> 
              We may employ third-party companies and individuals to:
            </p>
            <ul className="list-disc ml-6 space-y-2 mb-2">
              <li>Facilitate our Service.</li>
              <li>Provide the Service on our behalf.</li>
              <li>Perform Service-related tasks.</li>
              <li>Analyze Service usage.</li>
            </ul>
            <p>
              These third parties may access your personal information but are obligated to use it solely for performing tasks on our behalf.
            </p>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Security
            </h2>
            <p>
              We value your trust and use commercially acceptable methods to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.
            </p>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Links to Other Sites
            </h2>
            <p>
              This Service may contain links to other sites. We strongly advise you to review their privacy policies. We are not responsible for the content or practices of these third-party sites.
            </p>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Children’s Privacy
            </h2>
            <p>
              We do not knowingly collect information from children under 13. If we discover such data, we delete it immediately. If you believe your child has provided personal information, please contact us.
            </p>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Changes to This Privacy Policy
            </h2>
            <p>
              We may update this policy periodically. Please review this page for changes. This policy is effective as of 2024-11-07.
            </p>
          </section>
          <section>
            <h2 className="text-base font-bold text-gray-800 mb-2">
              Contact Us
            </h2>
            <p>
              If you have any questions about this Privacy Policy, contact us at <a href="mailto:contact@pickpackgo.app" className="text-red-600 underline">contact@pickpackgo.app</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
    </div>
  );
};
